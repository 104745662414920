












































import ListingHierarchiesMixin from '../mixins/ListingHierarchiesMixin'
import FamilyDialog from '../components/FamilyDialog.vue'
import ODataTable from '@/components/ODataTable.vue'
import IODataTableHeader from '@/models/IODataTableHeader'
import IListingHierarchy from '../models/IListingHierarchy'

export default ListingHierarchiesMixin.extend({
  components: {
    ODataTable,
    FamilyDialog
  },
  data() {
    return {
      activeItem: null as IListingHierarchy
    }
  },
  computed: {
    headers(): IODataTableHeader[] {
      return [
        {
          text: 'Name',
          value: 'name',
          searchable: true
        },
        {
          text: 'Description',
          value: 'description',
          searchable: true
        },
        {
          text: 'Comments',
          value: 'comments',
          searchable: true
        },
        {
          text: 'Sort Name',
          value: 'sortName',
          searchable: true
        },
        {
          text: 'Docs Product Slug',
          value: 'docsProductSlug',
          searchable: true
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: 1
        }
      ]
    }
  },
  methods: {
    async onSave(item) {
      await this.saveListingHierarchy(item)
      this.activeItem = null
    },
    async onDelete(item) {
      await this.deleteListingHierarchy(item)
    }
  }
})













































import FormMixin from '@/mixins/FormMixin'

export default FormMixin.extend({
  created() {
    this.form.nodeTypeId = 1
  }
})
